type Response<T> = {
  results: T[];
  links: {};
  totalRecords: number;
};

export type CalculatedQuestionPossibleAnswer = {
  key: string;
  label: string;
};

export type QuestionType =
  | 'TEXT'
  | 'CHOICE'
  | 'WRAPPER'
  | 'MULTI_CHOICE'
  | 'FILE';

export type Tag = {
  category: Category;
  subCategory: SubCategory;
};

export type Category = 'PILLAR' | 'RISK';

export enum SubCategoryType {
  SiteCharacteristics = 'SITE_CHARACTERISTICS',
  ManagementControls = 'MANAGEMENT_CONTROLS'
}

export type SubCategory =
  | 'LABOUR_STANDARDS_AND_RIGHTS'
  | 'HEALTH_AND_SAFETY'
  | 'ENVIRONMENT'
  | 'BUSINESS_ETHICS'
  | `${SubCategoryType}`;

export type QuestionResult = {
  id: string;
  subsectionId: string;
  label: string;
  questionType: QuestionType;
  possibleAnswers?: Answer[];
  tags?: Tag[];
};

export type CalculatedQuestionResult = {
  id: string;
  subsectionId: string;
  label: string;
  questionType: QuestionType;
  options?: CalculatedQuestionPossibleAnswer[];
  tags?: Tag[];
};

export type QuestionResponse = Response<QuestionResult>;

export type CalculatedQuestionResponse = Response<CalculatedQuestionResult>;

export type ThresholdAndOffsetsResponse = Response<ThresholdAndOffsetsResult>;

export type ThresholdAndOffsetsResult = {
  questionnaire: QuestionnaireType;
  thresholds: Thresholds;
};

export type ThresholdDataByType = {
  thresholdsData?: Record<string, number>;
  offsetsData?: Record<string, number>;
};

export type Thresholds = {
  totalThreshold: number;
  pillarThresholds: Record<string, number>;
  totalOffset: number;
  pillarOffsets: Record<string, number>;
};

export type ScoreResult = {
  id: string;
  questionnaire: QuestionnaireType;
  questionCode: string;
  scores: UpdateAnswerScore;
  createdTime: Date;
  updatedTime: Date;
};

type QMScoreResult = {
  id: string;
  module: QMType;
  questionCode: string;
  scores: UpdateAnswerScore;
  createdTime: Date;
  updatedTime: Date;
  risk: SubCategoryType;
};

export type ScoreResponse = Response<ScoreResult>;

export type QMScoreResponse = Response<QMScoreResult>;

export enum CoreSaqType {
  GP = 'gp',
  SP = 'sp',
  RA = 'ra',
  SA = 'sa'
}

export enum QMType {
  ENVIRONMENT = 'environment'
}

export type QuestionnaireType = CoreSaqType | QMType;

export type UpdateAnswerScore = {
  possibleAnswers?: ScorePossibleAnswer[];
  maxScore: number;
  type: ScoreType;
  basicAnswer?: BasicAnswer;
  bands?: Band[];
};

export type Band = {
  from?: number;
  to?: number;
  scores: CompanySizeScore;
};

export enum CompanySizeScoreType {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Micro = 'MICRO',
  Small = 'SMALL',
  Default = 'DEFAULT'
}

export type CompanySizeScore = Record<CompanySizeScoreType, number | undefined>;

export type BasicAnswer = {
  answered: CompanySizeScore | undefined;
  unanswered: CompanySizeScore | undefined;
};

export type ScorePossibleAnswer = {
  code: string;
  scores: CompanySizeScore;
};

export type ScoreType = 'choice' | 'basic' | 'banded';

export type SectionResult = {
  children: SectionResult[];
  code: string;
  description: string;
  id: string;
  name: string;
};

export type ScoreUpdate = {
  scoreId: string;
  scores: UpdateAnswerScore;
};

export type ColumnType = 'PILLAR' | 'QUESTION' | 'IS_SAQ' | 'SUBSECTION_NAME';

export type ColumnMeta = {
  name: string;
  property: ColumnType;
};

type SiteQuestionnaire = {
  id: string;
  questionnaireId: string;
  properties: {
    companySize: CompanySizeScoreType;
  };
};

export type SiteQuestionnaireStatusResult = {
  siteQuestionnaire: SiteQuestionnaire;
};

export enum AnswerDataTypeName {
  STRING = 'string',
  STRING_ARRAY = 'stringarray',
  CHOICE = 'choice',
  DATAGRID = 'datagrid',
  MULTICHOICE = 'multichoice',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  FILE = 'file'
}

export type AnswerData = {
  dataType: AnswerDataTypeName;
  value: unknown;
};

export type MultiChoiceAnswerValue = {
  answerCode: string;
  value: string;
};

export type ChoiceAnswerValue = Pick<MultiChoiceAnswerValue, 'answerCode'>;

export type FileAnswerValue = {
  fileName?: string;
  fileLink?: string;
  fileId?: string;
};

export type FileViewAnswerValue = {
  fileName?: string;
  fileLink?: string;
  fileDownloadLink?: string;
};

export type AnswerItem = {
  questionCode: string;
  answerData: AnswerData;
};

export type Answers = {
  id: string;
  siteQuestionnaireId: string;
  lastModified?: string;
  sectionId: string;
  answers: AnswerItem[];
};

export type AnswerResponse = {
  links: Record<string, unknown>;
  results: Answers[];
  totalRecords: number;
};

export type CalculatedRiskAnswersResult = {
  siteCode: string;
  answers: AnswerItem[];
};

export type WorkplaceRiskScoresResult = {
  siteCode: string;
  scores: WorkplaceRiskScores;
  lastModifiedAt: Date;
};

export type WorkplaceRiskScores = {
  siteCharacteristicsRisk: SiteCharacteristicsRisk;
  managementControlsRisk: ManagementControlsRisk;
};

export type SiteCharacteristicsRisk = {
  score: number;
  pillars: Record<string, number>;
  questionScores: Record<string, number>;
};

export type ManagementControlsRisk = {
  score: number;
  sectionScores: Record<string, number>;
  questionScores: Record<string, number>;
};

export type RiskData = {
  label: string;
  score: number;
};

export type QuestionRiskData = RiskData & {
  tags: string[];
  pillars: string[];
  sectionName: string | undefined;
  answer: string[] | FileAnswerValue[];
  answerScore: any;
  questionScore?: number;
  key: string;
};

export type SiteCharacteristicsRiskData = {
  totalScore: number;
  pillars: RiskData[];
  questionScores: QuestionRiskData[];
};

export type ManagementControlsRiskData = {
  totalScore: number;
  sectionScores: RiskData[];
  questionScores: QuestionRiskData[];
};

export type Questionnaire<T extends Question> = {
  id: string;
  type: QuestionnaireType;
  sections: Section<T>[];
};

export type Section<T extends Question> = {
  id: string;
  code: string;
  label: string;
  questions: T[];
};

export interface Question {
  id: string;
  label: string;
  answers: Answer[];
  subsection: string;
}

export interface QScore extends Question {
  answers: AScore[];
  maxScore: number;
  scoreId: string;
  tags: Tag[];
  scoreType: ScoreType;
}

export interface Answer {
  id: string;
  label: string;
}

export type Score = {
  label: string;
  value: number;
};

export interface AScore extends Answer {
  scores: Score[];
}

export interface BandAScore extends AScore {
  from: number;
  to: number;
}

export type BasicType = 'answered' | 'unanswered';

export interface BasicAScore extends AScore {
  type: BasicType;
}

type ActivityType = 'GOODS' | 'LABOUR' | 'SERVICE' | 'AGENT';
type WorkersProviderType = 'RECRUITMENT_AGENCY' | 'LABOUR_PROVIDER';
type LocationType = 'OWN' | 'NO_FIXED_LOCATION' | 'THIRD_PARTY';
type OwnLocationType = 'OFFICE' | 'NON_OFFICE';

export type WorkplaceActivityProfile = {
  activityType: ActivityType;
  workersProviderType?: WorkersProviderType;
  locationType?: LocationType;
  ownLocationType?: OwnLocationType;
};

export type Workplace = {
  id: string;
  workplaceCode: string;
  workplaceName: string;
  orgCode: string;
  address: Record<string, string>;
  usesLabourProviders: string;
  activityProfile: WorkplaceActivityProfile;
};

export type SectionNode = {
  id: string;
  name: string;
  code: string;
  description?: string;
  questionnaireId?: string;
  children: SectionNode[];
};
export type SectionsResponse = {
  id: string;
  name: string;
  code: string;
  sections: SectionNode[];
};
